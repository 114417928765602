<template>
  <v-sheet v-bind="propsCompSheet" data-cy="documents-delete">
    <template v-if="objectId">
      <div class="mb-8">
        {{ $t("documents.deleteConfirmation") }}
      </div>
      <v-container class="pa-0 mb-8">
        <v-row>
          <v-col cols="12" md="2">
            <div class="label-show">{{ $t("common.name") }}:</div>
          </v-col>
          <v-col cols="12" md="10">
            <div>{{ document.name }}</div>
          </v-col>
        </v-row>
      </v-container>
      <div class="d-flex">
        <v-btn
          v-bind="propsButton"
          :loading="status.loading"
          :disabled="!formReadyForSubmit"
          :to="{ name: 'documents-show', params: { id: objectId } }"
          data-cy="button-cancel"
          >{{ $t("common.cancel") }}</v-btn
        >
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="
            document.fileType &&
            (authCanByUserId('deleteOwn.documents', document.ownerId) ||
              authCan('deleteOthers.documents'))
          "
          v-bind="propsButtonTrash"
          :loading="status.loading"
          :disabled="!formReadyForSubmit"
          @click="clickDelete"
          data-cy="button-delete"
          >{{ $t("common.yes") }}</v-btn
        >
      </div>
    </template>
    <v-alert v-else type="error">
      {{ $t("documents.error") }}
    </v-alert>
  </v-sheet>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import Permissions from "@/modules/auth/mixins/Permissions.mixin";

export default {
  name: "DocumentDelete",
  mixins: [ComponentStatus, Permissions],
  props: {
    objectId: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  computed: {
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.status.readError) {
        return false;
      }
      // if (this.isAdmin) {
      //   return true;
      // }
      return true;
    },
    document() {
      return this.$store.getters["documents/readById"](this.objectId);
    },
  },
  watch: {
    objectId: {
      immediate: true,
      handler: function () {
        this.setStatusReading();
        this.$store
          .dispatch("documents/readById", this.objectId)
          .then((/* result */) => {
            this.setStatusReadSuccess();
          })
          .catch((/* error */) => {
            this.setStatusReadError();
          });
      },
    },
  },
  methods: {
    clickDelete() {
      this.sendDelete();
    },
    sendDelete() {
      this.setStatusDeleting();
      this.$store
        .dispatch("documents/delete", this.objectId)
        .then((/* result */) => {
          this.setStatusDeleteSuccess();
          this.$store.commit("status/showSuccess");
          this.$router.push({
            name: "documents-index",
          });
        })
        .catch((/* error */) => {
          this.setStatusDeleteError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
